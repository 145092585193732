import anime from 'animejs/lib/anime.es.js';
import { breakText } from './utils.js';

const animateHeader = () => {
  const selector = '.panel-content .t-large';
  breakText(selector);

  anime
    .timeline({ loop: false })
    .add({
      targets: `${selector} .letter`,
      scale: [4, 1],
      opacity: [0, 1],
      translateZ: 0,
      easing: 'easeOutExpo',
      duration: 50,
      delay: (el, i) => 25 * i,
    })
    .add({
      targets: `${selector}`,
      opacity: 1,
      duration: 1000,
      easing: 'easeOutExpo',
      delay: 500,
    });
};

animateSVG = (selector) => {
  anime({
    targets: `${selector} path`,
    strokeDashoffset: [anime.setDashoffset, 0],
    easing: 'easeInOutSine',
    duration: 5000,
    delay: anime.stagger(10),
    direction: 'alternate',
    loop: false,
    complete: function (anim) {
      const items = document.querySelectorAll(selector);
      items.forEach(item => {
        item.classList.remove('initial')
      })
      console.log('animation complete')
    },
  });
}

export { animateHeader, animateSVG };
