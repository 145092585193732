import { animateHeader, animateSVG } from './text.js'
import { modeSwitcher } from './mode.js'
import Accordion from './accordion.js'
import { Graph, init as graphInit } from './graph.js'

const copyright = () => {
  const placeholder = document.querySelector('.copyyear')
  if (!placeholder) return

  placeholder.innerHTML = new Date().getFullYear()
}

document.addEventListener('DOMContentLoaded', () => {
  console.log('DNZ scripts test loaded');

  // init accordions
  const accordions = document.querySelectorAll('.accordion h3');
  accordions.forEach((accordionEl) => {
    new Accordion(accordionEl)
  })

  copyright();

  animateSVG('.draw-in')
  modeSwitcher()
  graphInit()
});
