import { Chart, Colors, DoughnutController, ArcElement, Legend, Title } from 'chart.js'
import pattern from 'patternomaly'

const selectors = [
  'totalUnnecessaryEmissionsChart',
  'totalContentBreakdownChart',
];

const data = {
  'totalUnnecessaryEmissionsChart': {
    title: 'Unnecessary Emissions',
    dataset: [
      { year: 2010, count: 10 },
      { year: 2011, count: 20 },
      { year: 2012, count: 15 },
      { year: 2013, count: 25 },
      { year: 2014, count: 22 },
      { year: 2015, count: 30 },
      { year: 2016, count: 28 },
      { year: 2017, count: 28 },
      { year: 2018, count: 28 },
      { year: 2019, count: 28 },
      { year: 2020, count: 28 },
      { year: 2021, count: 28 },
    ]
  },
  'totalContentBreakdownChart': {
    title: 'Emissions by content type',
    dataset: [
      { year: 2010, count: 10 },
      { year: 2011, count: 20 },
      { year: 2012, count: 15 },
      { year: 2013, count: 25 },
      { year: 2014, count: 22 },
      { year: 2015, count: 30 },
    ]
  }
}

const colors = [
  '#a1fc54',
  '#ff3240',
  '#ffe58d',
  '#ff989f',
  '#59d8a1',
  '#4765f3',
  '#ffd6d9',
  '#a2b1f8',
  '#fffae8',
  'hsl(220.6, 13.2%, 46.1%)',
  '#1F6549',
  '#cfced6',
];

const patterns = [
  pattern.draw('plus', '#a1fc54', '#666'),
  pattern.draw('weave', '#ff3240'),
  pattern.draw('square', '#ffe58d', '#666'),
  pattern.draw('circle', '#ff989f', '#666'),
  pattern.draw('diamond', '#59d8a1', '#666'),
  pattern.draw('zigzag', '#4765f3', '#aaa'),
  pattern.draw('triangle', '#ffd6d9', '#666'),
  pattern.draw('cross', '#a2b1f8', '#666'),
  pattern.draw('ring', '#fffae8', '#666'),
  pattern.draw('box', 'hsl(220.6, 13.2%, 46.1%)', '#aaa'),
  pattern.draw('line', '#1F6549'),
  pattern.draw('dot', '#cfced6', '#666'),
];

const charts = []

const togglePatterns = checked => {
  charts.forEach(chart => {
    chart.data.datasets[0].backgroundColor = checked ? patterns : colors
    chart.update()
  })
}

const handlePatternToggle = evt => {
  let target = evt.target
  togglePatterns(target.checked)
}

const Graph = () => {
  Chart.register(
    Colors,
    DoughnutController,
    ArcElement,
    Legend,
    Title
  );

  selectors.forEach(selector => {
    const canvas = document.getElementById(selector)
    const context = canvas.getContext('2d')

    charts.push(
      new Chart(context, {
        type: 'doughnut',
        data: {
          labels: data[selector].dataset.map((row) => row.year),
          datasets: [
            {
              label: 'Acquisitions by year',
              data: data[selector].dataset.map((row) => row.count),
              backgroundColor: colors,
              borderColor: '#555',
              borderRadius: 5,
              borderWidth: 2,
              cutout: '65%',
            },
          ],
        },
        options: {
          responsive: true,
          plugins: {
            title: {
              display: true,
              text: data[selector].title,
              color: '#cfced6',
              align: 'center',
              fullSize: false,
              font: {
                weight: 'bold',
                size: 20,
                family: 'PP Object Sans, cursive, sans-serif',
              },
              position: 'bottom',
            },
            //   legend: {
            //     display: true,
            //     position: 'left',
            //     align: 'center',
            //     maxWidth: 400,
            //     labels: {
            //       usePointStyle: true,
            //       pointStyle: 'rect',
            //       filter: (legendItem, data) =>
            //         data.datasets[0].data[legendItem.index] != 0,
            //     },
            //   },
          },
        },
      })
    );
  })

  const patternTrigger = document.querySelector('[name="showPatterns"]')
  if (patternTrigger) {
    patternTrigger.addEventListener('change', handlePatternToggle)
  }
}

const init = () => {
  return Graph()
}

export { Graph, init }
