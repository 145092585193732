const breakText = selector => {
  // Function to break text into individual letters so we can animate them

  // Wrap every letter in a span
  var textWrapper = document.querySelector(selector);
  const content = textWrapper.textContent
  textWrapper.innerHTML = textWrapper.textContent.replace(
    /\S/g,
    "<span aria-hidden='true' class='letter'>$&</span>"
  )
  createSROnlyBlock(selector, content, true)
}

const createSROnlyBlock = (selector, content, pre = false) => {
  const div = document.createElement('div')
  div.classList.add('sr-only')
  div.innerHTML = content
  document.querySelector(selector)[(pre) ? 'prepend' : 'appendChild'](div)
}

export {
  breakText
}
