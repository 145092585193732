const modeSwitcher = () => {
  const check = document.querySelector('.dark-light [type="checkbox"]')

  check.addEventListener('click', handleModeChange)
}

const handleModeChange = evt => {
  console.log('switch has changed', evt.target.checked)
  document.body.dataset.theme = evt.target.checked ? 'dark' : 'light'
}

export {
  modeSwitcher
}
